<template>
  <div>
    <b-alert
      v-for="alert in alerts"
      :key="alert.id"
      dismissible
      :show="5"
      :variant="alert.type"
      @dismissed="closeAlert(alert.id)">
      {{ alert.data }}
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'Alert',
};
</script>
