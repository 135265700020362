import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

import i18n from './library/vue-i18n';
import fbSdk from './library/fb-sdk';
import './library/vue-element-ui';
import './library/vue-meta';
import './library/vue-feather';
import './library/vue-loading-overlay';
import './library/bootstrap-vue';
import './library/vue-fontawesome';
import './library/vue-perfect-scroll';
import './library/vue-apexchart';
import './library/vue-flag';
import './library/vue-sweet-alert';
import './library/v-calendar';

import 'leaflet/dist/leaflet.css';

import './mixins/alert';

import './assets/styles/core.css';
import './assets/styles/style.scss';
import './assets/vendors/dropify/dist/dropify.min.css';
// import './assets/vendors/dropify/dist/dropify.min.js';

import Alert from './ui/components/Alert.vue';

Vue.component('Alert', Alert);
fbSdk.initFacebookSdk();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
