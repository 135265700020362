import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import ui from './modules/ui';
import workspace from './modules/workspace';
import report from './modules/report';
import channels from './modules/channels';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    ui,
    workspace,
    report,
    channels,
  },
});
