/* eslint-disable */
const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID;

export default {
  initFacebookSdk() {
    return new Promise((resolve) => {
      // wait for facebook sdk to initialize before starting the vue app
      console.log('initFacebookSdk');
      window.fbAsyncInit = () => {
        FB.init({
          appId: facebookAppId,
          cookie: true,
          xfbml: true,
          version: 'v8.0',
        }, (error, resp) => {
          console.log(error, resp);
        });

        // auto authenticate with the api if already logged in with facebook
        console.log('getLoginStatus');
        FB.getLoginStatus(({
          authResponse,
        }) => {
          if (authResponse) {
            accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
          } else {
            console.log('no authResponse');
            resolve();
          }
        });
      };

      // load facebook sdk script
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    });
  },
};
