import ls from '@/library/localStorage';

export default {
  namespaced: true,
  state: {
    activeChannel: ls.get('channel') || {},
  },
  getters: {
    activeChannel(state) {
      return state.activeChannel;
    },
  },
  mutations: {
    setActiveChannel(state, channel) {
      state.activeChannel = channel;
    },
    clearActiveChannel(state) {
      state.activeChannel = {};
    },
  },
  actions: {
    setActiveChannel({ commit }, channel) {
      commit('setActiveChannel', channel);
      ls.set('channel', channel);
    },
  },
};
