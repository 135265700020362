import ls from '@/library/localStorage';

export default {
  namespaced: true,
  state: {
    activeBatch: ls.get('batch') || {},
  },
  getters: {
    activeBatch(state) {
      return state.activeBatch;
    },
  },
  mutations: {
    setActiveBatch(state, batch) {
      state.activeBatch = batch;
    },
    clearActiveBatch(state) {
      state.activeBatch = {};
    },
  },
  actions: {
    setActiveBatch({ commit }, batch) {
      commit('setActiveBatch', batch);
      ls.set('batch', batch);
    },
  },
};
