export default {
  set(key, val, exp = 8640000) {
    if (!val) return;
    const data = {
      val,
      exp: exp ? Date.now() + exp : undefined,
    };
    localStorage.setItem(key, btoa(unescape(encodeURIComponent(JSON.stringify(data)))));
  },
  get(key) {
    const resultStr = localStorage.getItem(key);
    if (!resultStr) return null;
    const result = JSON.parse(atob(resultStr));
    if (result.exp < Date.now()) {
      this.remove(key);
      return null;
    }
    return result.val;
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  },
};
