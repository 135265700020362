import Vue from 'vue';

import store from '../store';

Vue.mixin({
  data() {
    return {
      alerts: [],
    };
  },
  mounted() {
    this.showAlert();
  },
  methods: {
    showAlert() {
      this.alerts = store.state.ui.alert;
    },
    closeAlert(id) {
      store.dispatch('ui/removeAlert', id);
    },
  },
});
